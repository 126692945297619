import axios from 'axios'

interface Person {
  email: string
  id: string
}

export const usePageViewEvent = (pageType: string, person: Person | null) => {
  const sendPageViewEvent = async () => {
    if (person !== null) {
      const { data: userData } = await axios.post('/api/getClientData', {
        userId: person?.id,
        headers: {
          'Content-Type': 'aplication/json',
          'REST-Range': 'resources=0-10',
        },
      })

      const { data: userAddressData } = await axios.post(
        '/api/getUserAddressInfo',
        {
          userId: userData?.[0]?.id,
        }
      )

      window.dataLayer.push({
        name: 'pageView',
        params: {
          location: window.location.href,
          page: window.location.pathname,
          visitorId: person?.id ?? null,
          email: person?.email ?? null,
          phone: userData?.[0]?.homePhone ?? null,
          city: userAddressData?.[0]?.city ?? null,
          state: userAddressData?.[0]?.state ?? null,
          cep: userAddressData?.[0]?.postalCode ?? null,
          userId: userData?.[0]?.id ?? null,
          user_logged: !!person?.id,
          user_id: person?.id ?? null,
          pageType,
        },
      })
    } else {
      window.dataLayer.push({
        name: 'pageView',
        params: {
          location: window.location.href,
          page: window.location.pathname,
          user_logged: false,
          user_id: null,
          pageType,
        },
      })
    }
  }

  return { sendPageViewEvent }
}
