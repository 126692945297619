import React, { useEffect, useState, useRef, lazy, Suspense } from 'react'
import type { PageProps } from 'gatsby'
import type { HomePageQueryQuery } from '@generated/graphql'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { graphql } from 'gatsby'
import { mark } from 'src/sdk/tests/mark'
import { resetCart, useSession } from 'src/sdk/session'
import Seo from 'src/views/Home/Seo'
import axios from 'axios'
import Above from 'src/views/Home/Above'
import lazyRetry from 'src/utils/lazyRetry'

import '../styles/pages/index.scss'

const Below = lazy(() =>
  lazyRetry(() => import('src/views/Home/Below'), 'Below')
)

export type Props = PageProps<HomePageQueryQuery>
function Page(props: Props) {
  const [preview, setPreview] = useState(null)
  const [didMount, setDidMount] = useState<boolean>(false)

  const { person } = useSession()
  const { sendPageViewEvent } = usePageViewEvent('Home', person)

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    window.insider_object = window.insider_object || {}
    window.insider_object.page = {
      type: 'Home',
      originalType: 'Home',
    }
  }, [])

  const getCmsData = async (
    contentType: string,
    documentId: string,
    versionId: string
  ) => {
    await axios
      .post(
        '/api/getCmsContent',
        {
          contentType,
          documentId,
          versionId,
        },
        {
          headers: {
            Accept: 'application/vnd.vtex.ds.v10+json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((response) => {
        switch (contentType) {
          case 'home':
            setPreview(response?.data?.sections)
            break

          case 'institutionalPage':
            window.location.href = `preview-institutional?contentType=institutionalPage&documentId=${documentId}&versionId=${versionId}`
            break

          case 'collectionPage':
            window.location.href = `${response?.data?.config?.collectionConfig?.slug}?contentType=collectionPage&documentId=${documentId}&versionId=${versionId}`
            break

          case 'ProductPage':
            window.location.href = `${response?.data?.config?.productConfig?.slug}?contentType=ProductPage&documentId=${documentId}&versionId=${versionId}`
            break

          default:
            window.location.href = `${response?.data?.config?.slugAndFilterConfig?.slug}?contentType=institutionalPage&documentId=${documentId}&versionId=${versionId}`
        }
      })
      .catch((error) => {
        console.error('Error -> ', error)
      })
  }

  useEffect(() => {
    resetCart()
    const SearchParams = new URLSearchParams(window?.location?.search)
    const contentType = SearchParams.get('contentType')
    const documentId = SearchParams.get('documentId')
    const versionId = SearchParams.get('versionId')

    if (
      contentType &&
      contentType !== 'globalComponents' &&
      documentId &&
      versionId
    ) {
      getCmsData(contentType, documentId, versionId)
    }
  }, [])

  return (
    <>
      <Seo data={props.data} location={props.location} />
      <Above data={props.data} preview={preview} />
      <Suspense fallback={null}>
        <Below data={props.data} preview={preview} />
      </Suspense>
    </>
  )
}

export const querySSG = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
    allCmsHome {
      edges {
        node {
          sections {
            name
            data
          }
        }
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export const fragment = graphql`
  fragment ProductSummary_product on StoreProduct {
    id: productID
    slug
    sku
    releaseDate
    brand {
      brandName: name
    }
    name
    gtin
    isVariantOf {
      name
      productGroupID
      additionalProperty {
        propertyID
        name
        value
        valueReference
      }
      hasVariant {
        ...skuFragment
      }
    }
    additionalProperty {
      propertyID
      name
      value
      valueReference
    }
    image {
      url
      alternateName
    }
    brand {
      name
    }
    offers {
      lowPrice
      offers {
        availability
        price
        listPrice
        quantity
        seller {
          identifier
        }
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)